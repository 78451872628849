:root {
  --background-color: #121212; /* Dark mode background */
  --text-color: #e0e0e0; /* Dark mode text color */
  --nav-background: #2a2a2a; /* Dark mode nav background */
  --nav-text-color: #ffffff; /* Dark mode nav text color */
  --article-title-color: #ffffff;
}

body {
  font-family: 'IBM Plex Mono', monospace; /* Apply the font to all text */
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-size: 14px;
}



.dark-mode {
  background-color: var(--background-color); /* Dark mode background */
  color: var(--text-color); /* Dark mode text color */
}

.light-mode {
  background-color: #ffffff; /* Light mode background */
  color: #000000; /* Light mode text color */
  --article-title-color: #000000;

}

.light-mode header {
  background-color: #f0e5d1; /* Light mode nav background */
  color: #000000; /* Light mode nav text color */
}

header {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 1rem; /* Padding for the header */
  background-color: rgba(42, 42, 42, 0.5); /* Semi-transparent background */
  color: var(--nav-text-color); /* Use variable for text color */
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure it stays above other content */
  backdrop-filter: blur(10px); /* Apply blur effect to the background */
}

nav {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between items */
  width: 100%; /* Ensure nav takes full width */
}

.logo_1 {
  width: 100px; /* Set a larger width for the logo (2x the original size) */
  height: auto; /* Maintain aspect ratio */
  margin: 0 1rem; /* Add some margin for spacing */
  display: block; /* Make it a block element */
  position: absolute; /* Position it absolutely */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for the width of the logo */
  display: none; /* Hide by default */
}

.logo_2 {
  width: 100px; /* Set a larger width for the logo (2x the original size) */
  height: auto; /* Maintain aspect ratio */
  margin: 0 1rem; /* Add some margin for spacing */
  display: block; /* Make it a block element */
  position: absolute; /* Position it absolutely */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for the width of the logo */
  display: block; /* Show by default */
}

.dark-mode .logo_1 {
  display: block; /* Show logo_1 in dark mode */
}

.dark-mode .logo_2 {
  display: none; /* Hide logo_2 in dark mode */
}

.light-mode .logo_1 {
  display: none; /* Hide logo_1 in light mode */
}

.light-mode .logo_2 {
  display: block; /* Show logo_2 in light mode */
}

header img {
  width: 50px; /* Set a fixed width for the logo */
  height: auto; /* Maintain aspect ratio */
  margin: 0 1rem; /* Add some margin for spacing */
  display: block; /* Make it a block element */
}

.main-container {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
}

aside {
  width: 20%; /* Adjust this if needed */
  max-width: 300px; /* {{ edit_1 }} */
  padding: 1rem;
  border-right: 1px solid var(--bg-color2);
}


main {
  width: 100%; /* Ensure main takes full width */
  padding: 1rem;
  overflow-y: auto;
}

#article-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr)); /* Limit to 5 columns */
  max-width: 100%; /* Ensure it takes full width of the parent */
  gap: 1rem; /* Space between grid items */
  width: 100%; /* Ensure it takes full width of the parent */
}

/* Media queries for responsive design */
@media (max-width: 1650px) {
  #article-grid {
      grid-template-columns: repeat(4, minmax(150px, 1fr)); /* Adjust for smaller screens */
  }
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
  #article-grid {
      grid-template-columns: repeat(3, minmax(150px, 1fr)); /* Adjust for smaller screens */
  }
}

@media (max-width: 768px) {
  #article-grid {
      grid-template-columns: repeat(2, minmax(150px, 1fr)); /* 2 columns on extra small screens */
  }
}

.article-card {
  background-color: var(--bg-color2);
  border-radius: 8px;
  overflow: hidden; /* Ensure content does not overflow */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Ensure smooth transitions */
  display: flex;
  flex-direction: column;
  opacity: 1; /* Ensure cards are fully visible by default */
  width: 100%; /* Make cards take full width of the grid cell */
  margin: auto; /* Center cards in their grid cell */
  cursor: pointer; /* Change cursor to pointer for better UX */
  padding: 1px; /* Add padding to increase height by 1 pixel */
  max-width: 600px;
  border: 1px solid var(--bg-color3);
}

.article-card:hover {
  transform: translateY(-5px); /* Move the card up by 5px on hover */
}

.article-card a {
  display: block; /* Make the anchor cover the entire card */
  height: 100%; /* Ensure it takes full height */
  color: inherit; /* Inherit text color */
  text-decoration: none; /* Remove underline */
}

.article-image {
  width: 100%; /* Ensure the image takes the full width of the card */
  height: auto; /* Maintain aspect ratio */
  max-height: 340px; /* Set a maximum height for the image */
  object-fit: cover; /* Cover the area without distortion */
  border-top-left-radius: 8px; /* Rounded corners for the top */
  border-top-right-radius: 8px; /* Rounded corners for the top */
}

.article-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow content to grow */
}

.article-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.article-type {
  background-color: #3a3a3a;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem; /* Adjust font size for article type */
}

.article-date {
  font-size: 0.8rem; /* Adjust font size for date */
  color: #aaa;
}

.article-title {
  font-size: 1.8rem; /* Adjust font size for h1 */
  margin: 0; /* Remove default margin */
  color: white; /* Set title color to white */
}

.article-span-h {
  font-size: 12px; /* Adjust font size for h1 */
}

.article-title-big {
  font-size: 2rem; /* Adjust font size for h1 */
  margin: 0; /* Remove default margin */
  color: white; /* Set title color to white */
}

.article-excerpt {
  font-size: 0.9rem; /* Adjust font size */
  color: #ddd;
  margin-bottom: 0.5rem; /* Reduce margin for compactness */
}

.article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.article-author {
  font-size: 0.6rem; /* Adjust font size */
  color: ver(--text-color);
}

.article-rank {
  margin: 0.2rem 0; /* Adjust margin for spacing */
  font-size: 0.6rem; /* Adjust font size */
  background-color: #f0b90b; /* Background color for rank */
  color: white; /* Text color */
  padding: 1px 3px; /* Padding for the rank display */
  border-radius: 4px; /* Rounded corners */
  display: inline-block; /* Ensure it behaves like an inline element */
}

.search-container {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  margin-bottom: 0; /* Remove space below the search container */
}

.search-bar {
  width: calc(100% - 1rem); /* Set width to match the scroll box, accounting for padding */
  padding: 0.5rem; /* Padding for better appearance */
  margin-bottom: 0; /* Remove space below the search bar */
  background-color: var(--bg-color2);  
  /*background-color: #ffffff;*/
  color: var(--text-color); /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  font-size: 1rem; /* Font size */
}

.research-tier-select,
.article-type-select {
  width: 100%; /* Full width */
  padding: 0.5rem; /* Padding for better appearance */
  margin-bottom: 1rem; /* Space below the select box */
  background-color: var(--bg-color2); /* Background color */
  color: var(--text-color);; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
}

.tag-scroll-container {
  max-height: 150px; /* Set a maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0.5rem; /* Optional padding */
  background-color: #2a2a2a; /* Match the sidebar background */
  border-radius: 4px; /* Optional rounded corners */
  border: none; /* Remove the border */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align items to the top */
}

/* Style the scrollbar */
.tag-scroll-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.tag-scroll-container::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

.tag-scroll-container::-webkit-scrollbar-track {
  background: #2a2a2a; /* Background of the scrollbar track */
}

/* Style the labels for better appearance */
.tag-scroll-container label {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  color: #ffffff; /* Text color */
  margin: 0.1rem 0; /* Reduced space between checkboxes */
  cursor: pointer; /* Change cursor to pointer */
}

/* Revert checkbox styles to default */
.tag-scroll-container input[type="checkbox"] {
  appearance: checkbox; /* Use default checkbox styling */
  margin-right: 0.5rem; /* Space between checkbox and label */
  cursor: pointer; /* Change cursor to pointer */
}

/* Optional: Add a hover effect */
.tag-scroll-container input[type="checkbox"]:hover {
  outline: none; /* Remove outline on hover */
}

/* Adjust the tag search input to match the width of the scroll box */
#tag-search {
  width: calc(100% - 1rem); /* Set width to match the scroll box, accounting for padding */
  padding: 0.5rem; /* Padding for better appearance */
  background-color: #3a3a3a; /* Background color */
  color: #ffffff; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 0.5rem; /* Space below the search bar */
}

#tag-container {
  /* max-height: 50%; */ /* Reverted change to original state */
  overflow-y: auto; /* Ensure the scrollbar appears */
}

/* Existing styles... */

/* Article page styles */
body {
  font-family: 'IBM Plex Mono', monospace; /* Changed font to IBM Plex Mono */
  background-color: #121212;
  color: #e0e0e0;
  line-height: 1.6;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #363636;
  padding: 1rem 2rem;
}

nav a {
  color: #bb86fc;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #3700b3;
}

#article-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

#article-meta {
  font-size: 0.7rem;
  color: #888;

}

#article-content {
  font-size: 0.7rem;
}

#article-content p {
  margin-bottom: 1.3rem;
}

#article-content strong {
  color: var(--glow-color);
}

@media (max-width: 768px) {
  main {
      padding: 1rem;
  }

  article {
      padding: 2rem;
  }

  #article-title {
      font-size: 2.5rem;
  }
}

/* New styles for article images */
#article-content img {
  width: 100%; /* Ensure images are responsive and scale with the container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  margin: 2rem auto; /* Center the image with auto margins */
  display: block; /* Make the image a block element */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Adjust spacing for headings that follow images */
#article-content img + h2 {
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 728px) {
  #article-content img {
      margin: 1.5rem 0;
  }
}

#a_main {
  max-width: 700px; /* Set maximum width to 700px */
  margin: 0 auto; /* Center the container */
  padding: 2rem; /* Padding for the container */
}

.light-mode {
  --background-color: #ffffff; /* Light mode background */
  --text-color: #000000; /* Light mode text color */
}

.article-subtitle {
  font-size: 1.3rem; /* Adjust font size for h3 */
  margin: 0.5rem 0; /* Add some margin for spacing */
  color: --nav-text-color; /* Set subtitle color to white */

  
}


.theme-button {
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  font-size: 24px; /* Icon size */
  position: relative; /* Position for glowing effect */
  color: inherit; /* Inherit text color */
  margin-left: 10px; /* Margin for spacing */
}

#article-content ul,
#article-content ol {
  margin-bottom: 1em; /* Add space after the list */
}

#article-content li {
  margin-bottom: 1em; /* Increase space between list items */
}

/* Optional: Adjust spacing for the last item */
#article-content li:last-child {
  margin-bottom: 0;
}







#sun-icon {
 /* color: #ffcc00;  Sun color */
 color: white;
}

#moon-icon {
  text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #ffffff; /* Glowing effect for sun */
  color: #000000;
  
  transition: all 0.3s ease;
  padding: 2px 3px;
 
  display: flex;
  align-items: center;
  justify-content: center; /* Moon color */
}

/* Ensure icons are visible */
.dark-mode #sun-icon {
  display: inline; /* Show sun icon in dark mode */
}

.light-mode #moon-icon {
  display: inline; /* Show moon icon in light mode */
}

/* Glowing effect */
#sun-icon {
  text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff; /* Glowing effect for sun */
  
  
  transition: all 0.3s ease;
  padding: 2px 3px;
 
  display: flex;
  align-items: center;
  justify-content: center;
}

#sun-icon:hover {
  filter: drop-shadow(0 0 7px #8CE3CD);
  color: #76f9c7;
  
  transform: scale(1.07);
}

#moon-icon:hover {
  filter: drop-shadow(0 0 7px #478c68);
  color: #0e5e48;
  transform: scale(1.07);
}

#moon-icon {
  text-shadow: 0 0 10px #6a5acd, 0 0 20px #6a5acd, 0 0 30px #6a5acd; /* Glowing effect for moon */
}

:root {
  --bg-color: #4d4d4d;
  --text-color: #fff;
  --border-color: #fff;
  --hover-bg-color: #444;
  --hover-text-color: #76f9c7; /* Forest Green */
  --glow-color: #76f9c7; /* Forest Green for glow in dark mode */
  --test: #1a1a1a;
  --bg-color2: #333;
  --bg-color3: #ffffff;
  --rank_f: #66BB6A;
  --rank_s: #AB47BC;
  --bg-color4: #474747;
}

.light-mode {
  --bg-color: #fdfaf1;
  --text-color: #000000;
  --border-color: #000000;
  --hover-bg-color: #f0f0f0;
  --hover-text-color: #478c68; /* Light Green */
  --glow-color: #478c68; /* Light Green for glow in light mode */
  --test: #fdfaf1;
  --bg-color2: #F0E5D1;
  --bg-color4: #F0E5D1;
  --bg-color3: #000000;
  --rank_f: #4CAF51;
  --rank_s: #9C27B0;
}

.back-button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 0px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  position: relative;
  font-family: 'Courier New', monospace;
}

.back-button:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  border-color: var(--hover-text-color);
  box-shadow: 0 0 15px var(--glow-color);
  transform: scale(1.05);
}

.back-button:active {
  transform: scale(0.95);
  box-shadow: 0 0 5px var(--glow-color);
}

@keyframes scanline {
  0% {
      transform: translateY(-100%);
  }
  100% {
      transform: translateY(100%);
  }
}

.back-button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--glow-color);
  opacity: 0;
  animation: scanline 2s linear infinite;
  pointer-events: none;
}

.back-button:hover:after {
  opacity: 0.3; /* Slightly more visible scanline */
}


.light-mode .article-title {
  color: #000000; /* Set article title color to black in light mode */
}

/* Ensure article title is white in dark mode */
.dark-mode .article-title {
  color: white; /* Set article title color to white in dark mode */
}

.light-mode .article-title-big {
  color: #000000; /* Set article title color to black in light mode */
}

/* Ensure article title is white in dark mode */
.dark-mode .article-title-big {
  color: white; /* Set article title color to white in dark mode */
}

.footer {
  text-align: center; /* Center the text */
  padding: 2rem; /* Padding for the footer */
  position: relative; /* Positioning context */
  bottom: 0; /* Stick to the bottom */
  width: 100%; /* Full width */
  font-family: 'Courier New', monospace; /* Hacker-style font */
}



.social-links {
  margin-top: 1rem; /* Space above social links */
  display: grid; /* Use grid for layout */
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)); /* Responsive grid */
  gap: 1rem; /* Space between icons */
}

.social-icon {
  color: #00ff00; /* Neon green icon color */
  font-size: 2rem; /* Icon size */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.social-icon:hover {
  color: #ff00ff; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Modern Footer Styles for 2024 */
.footer {
  color: var(--footer-text-color);
  padding: 3rem 0;
  font-family: 'Courier New', monospace;
}
.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-section {
  flex: 1;
  margin: 3rem;
  min-width: 200px;
}
.footer-section h3 {
  color: var(--footer-text-color);
  margin-bottom: 1rem;
  font-size: 20px;
}
.footer-section ul {
  list-style-type: none;
  padding: 0;
}
.footer-section ul li {
  margin-bottom: 0.5rem;
}
.footer-section ul li a {
  color: var(--footer-text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-section ul li a:hover {
  color: var(--glow-color);
}

/* Day Mode Styles */
.light-mode .footer {
  background-color: #f0e5d1; /* Light gray background in light mode */
  color: #000000; /* Black text in light mode */
}

/* Night Mode Styles */
.dark-mode .footer {
  background-color: #363636; /* Dark background in dark mode */
  color: #fff; /* White text in dark mode */
}
.social-icons a {
  color: var(--footer-text-color);
  font-size: 1.5rem;
  margin-right: 1rem;
  text-decoration: none;
  
  transition: color 0.3s ease, filter 0.3s ease, transform 0.3s ease;
}
.social-icons a:hover {
  color: var(--glow-color);
  filter: drop-shadow(0 0 7px var(--glow-color));
  transform: scale(1.07);
}

#logo_foot:hover {
  color: var(--glow-color);
  filter: drop-shadow(0 0 7px var(--glow-color));
  transform: scale(1.07);
}

.social-icon:hover {
  transform: scale(1.07);
}

.newsletter-form input[type="email"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  color: #ffffff; /* Text color */
}

.dark-mode .newsletter-form input[type="email"] {
  border: 1px solid #ffffff;
  background-color: #3a3a3a; /* Background color */
}

.light-mode .newsletter-form input[type="email"] {
  border: 1px solid #000000; /* Black border */
  background-color: #ffffff; /* White background */
}
.newsletter-form button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.newsletter-form button:hover {
  background-color: #2980b9;
}
.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #ecf0f1;
}

/* Specific styles for button (#submit_e) */
#submit_e, .btn {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 4.5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 0px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  position: relative;
  font-family: 'Courier New', monospace;
  margin-top: -8px;
}

/* Common hover styles - only for enabled buttons */
#submit_e:not(:disabled):hover, .btn:not(:disabled):hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  border-color: var(--hover-text-color);
  box-shadow: 0 0 15px var(--glow-color);
  transform: scale(1.05);
}

/* Common active styles - only for enabled buttons */
#submit_e:not(:disabled):active, .btn:not(:disabled):active {
  transform: scale(0.95);
  box-shadow: 0 0 5px var(--glow-color);
}

/* Scanline effect */
#submit_e:after, .btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--glow-color);
  opacity: 0;
  animation: scanline 2s linear infinite;
  pointer-events: none;
}

/* Scanline effect on hover - only for enabled buttons */
#submit_e:not(:disabled):hover:after, .btn:not(:disabled):hover:after {
  opacity: 0.3; /* Slightly more visible scanline */
}

/* Disabled state styles */
#submit_e:disabled, .btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Ensure no hover effects for disabled buttons */
#submit_e:disabled:hover, .btn:disabled:hover,
#submit_e:disabled:active, .btn:disabled:active {
  background-color: var(--bg-color);
  color: var(--text-color);
  border-color: var(--border-color);
  box-shadow: none;
  transform: none;
}

/* Ensure no scanline effect for disabled buttons */
#submit_e:disabled:after, .btn:disabled:after,
#submit_e:disabled:hover:after, .btn:disabled:hover:after {
  animation: none;
  opacity: 0;
}

/* Keyframe animation for the scanline effect */
@keyframes scanline {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.gate-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark, semi-transparent background */
  backdrop-filter: blur(5px); /* Blurry effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500; /* Ensure it's above other content */
}

.login-box {
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: var(--login-box-bg);
  color: var(--login-box-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light mode styles */
:root {
  --login-box-bg: #ffffff;
  --login-box-text: #333333;
}

/* Dark mode styles */
.dark-mode {
  --login-box-bg: #2a2a2a;
  --login-box-text: #ffffff;
}

.button-container {
  display: flex;
  align-items: center;
}



a {
  color: var(--footer-text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}
a:hover {
  color: var(--glow-color);
}


.article-title1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 10px 0;
  font-size: 0.8rem;
}

.article-rank {
  margin: 0.2rem 0;
  font-size: 0.6rem;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
}

.rank-free {
  
  color: var(--text-color); /* Black text for better contrast on light background */
}

.rank-supporter {
  background-color: var(--rank_s); /* Purple */
  color: #ffffff;
}

.rank-default {
  background-color: #808080; /* Gray */
}



.sidebar {
  background-color: var(--bg-color);
}

.author-img-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

#article-meta {
  display: flex;
  align-items: center;
  
}










.pagination-container {
  
  padding: 20px 0;
}

.pagination-container:last-child {
  margin-top: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  
}

.pagination-button {
  padding: 5px 10px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.pagination-button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}



/* Common hover styles */
.pagination-button:not(.disabled):hover, .btn:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
  border-color: var(--hover-text-color);
  box-shadow: 0 0 15px var(--glow-color);
  transform: scale(1.05);
}

/* Common active styles */
.pagination-button:not(.disabled):active, .btn:active {
  transform: scale(0.95);
  box-shadow: 0 0 5px var(--glow-color);
}

/* Scanline effect */
.pagination-button:not(.disabled):after, .btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--glow-color);
  opacity: 0;
  animation: scanline 2s linear infinite;
  pointer-events: none;
}


.pagination-current {
  font-weight: bold;
}


.article_text {
  font-size: 14px;
}
